<template lang="pug">
  #sidebar-wrapper(:class="{ hidden: isHidden, maestro: isSuperadmin || isOrganizationAdmin }")
    #sidebar
      .header
        img.logo(src="@/assets/images/metroconductor-black.svg")
        .hide-sidebar(@click="toggleSidebar")
          AppIcon(name="sandwich")
      ul.links(v-if="isLoggedIn")
        Link(
          v-for="link in linksToShow"
          :key="link.title"
          :link="link"
          :is-sidebar-hidden="isHidden"
          @nested-links-toggle="nestedLinksToggle(link)"
        )
</template>

<script>
  import maestroLinks from "./maestroLinks"
  import userLinks from "./userLinks"
  import otaAdminLinks from "./otaAdminLinks"
  import organizationsAdminLinks from "./organizationsAdminLinks"

  // misc
  import { isLoggedIn } from "@/helpers/auth"
  import { isSuperadmin, isOrganizationAdmin, isOtaAdmin } from "@/helpers/permission"

  export default {
    props: {
      isHidden: {
        type: Boolean,
        required: true
      }
    },

    components: {
      Link: () => import("@/components/layout/Sidebar/Link"),
      AppIcon: () => import("@/components/elements/AppIcon")
    },

    data() {
      return {
        links: []
      }
    },

    mounted() {
      this.setLinks()
    },

    watch: {
      isLoggedIn() {
        this.setLinks()
      }
    },

    computed: {
      isLoggedIn,
      isSuperadmin,
      isOtaAdmin,
      isOrganizationAdmin,

      linksToShow() {
        return this.links.filter(l => this.showLink(l))
      }
    },

    methods: {
      setLinks() {
        let links = []

        if (this.isLoggedIn) {
          if (this.isSuperadmin) {
            links = maestroLinks()
          } else if (this.isOtaAdmin) {
            links = otaAdminLinks()
          } else if (this.isOrganizationAdmin) {
            links = organizationsAdminLinks()
          } else {
            links = userLinks()
          }
        }

        this.links = links
      },

      toggleSidebar() {
        this.$emit("switch")

        if (this.isHidden) {
          this.links.forEach(link => (link.opened = false))
        }
      },

      nestedLinksToggle(link) {
        if (this.isHidden) {
          this.toggleSidebar()
        }

        link.opened = !link.opened
      },

      showLink(link) {
        const { shouldRender } = link

        const render = this.shouldRenderLink(shouldRender)

        if (this.isSuperadmin) {
          return !link.hideFromSuperadmin && render
        }

        return render
      },

      shouldRenderLink(shouldRender) {
        switch (typeof shouldRender) {
          case "function":
            return shouldRender()
          case "undefined":
            return true
          default:
            return shouldRender
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  @mixin collapsed
    max-width: 0
    opacity: 0
    overflow: hidden

  #sidebar-wrapper
    background: $default-white
    box-shadow: 0px 0px 25px 0px transparentize($default-black, 0.9)
    min-height: 100%
    min-width: $sidebar-width
    width: $sidebar-width

    &.maestro
      background: lighten($default-purple-light, 3%)

      ::v-deep
        .link
          background: lighten($default-purple-light, 5%)
          border: none

          &:hover
            background: lighten($default-purple-light, 6%)

          &.active
            background: darken($default-purple-light, 1%)
            &, *
              cursor: default

    &.hidden
      max-width: $sidebar-collapsed-width
      min-width: $sidebar-collapsed-width

      #sidebar
        .header
          .hide-sidebar
            position: static

      .links
        margin: 0 !important

      .logo
        +collapsed

      ::v-deep
        .link-content
          +collapsed

        .nested
          display: none

        .app-icon
          margin: 0

        .link-wrapper
          display: flex
          justify-content: center

      #sidebar
        width: auto

        .header
          justify-content: center
          margin: 25px 0
          width: $sidebar-collapsed-width

    #sidebar
      height: 100vh
      position: fixed
      overflow-x: hidden
      width: $sidebar-width
      padding-bottom: 3rem

      .header
        align-items: center
        display: flex
        margin: 25px 20px
        max-height: 52px

        .logo
          width: 200px

        .hide-sidebar
          color: #3558c7
          cursor: pointer
          width: 32px
          padding: 5px 8px
          transition: background 0.15s ease
          border-radius: 5px
          position: absolute
          right: 5px

          &:hover
            background: lighten($default-purple-light, 5%)

          ::v-deep
            .svg-fillable
              fill: $default-purple

      .links
        align-items: center
        display: flex
        flex-direction: column
        gap: 0.5rem
        line-height: $link-container-height
        list-style: none
        margin: 0 20px
        padding: 0
</style>
