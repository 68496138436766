<template lang="pug">
  .link-content
    span {{ title }}
    AppBadge(
      v-if="unreadNotificationsCount > 0"
      :text="formattedText"
    )
</template>

<script>
  // store modules
  import notificationsModule from "@/config/store/notifications"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withWebSocket from "@/mixins/withWebSocket"

  const notificationsMixin = withStoreModule(notificationsModule, {
    name: "notificationsSidebar",
    readers: { notificationsLoading: "loading", unreadNotificationsCount: "unreadItemsCount" },
    actions: { fetchUnreadNotificationsCount: "FETCH_UNREAD_ITEMS_COUNT" },
    mutations: { addUnreadNotificationsCount: "ADD_UNREAD_ITEMS_COUNT" }
  })

  export default {
    props: {
      title: String
    },

    components: {
      AppBadge: () => import("@/components/elements/AppBadge")
    },

    mixins: [notificationsMixin, withWebSocket],

    mounted() {
      this.fetchUnreadNotificationsCount()

      this.webSocketSubscribe("NotificationsChannel", {
        received: this.fetchUnreadNotificationsCount
      })
    },

    computed: {
      formattedText({ unreadNotificationsCount }) {
        return unreadNotificationsCount.toString()
      }
    }
  }
</script>
