export default () => [
  {
    title: "components.sidebar.company_list",
    icon: "sitemap",
    page: "OtaManagement/CompanyList"
  },
  {
    title: "components.sidebar.plan_list",
    icon: "gem",
    page: "OtaManagement/PlanList"
  },
  {
    title: "components.sidebar.booking_confirmation",
    icon: "calendarPlus",
    page: "OtaManagement/BookingConfirmation"
  }
]
